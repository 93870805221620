import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Medipass provides two options for processing transactions:`}</p>
    <ul>
      <li parentName="ul">{`Virtual Terminal: used for processing simple cardholder payments`}</li>
      <li parentName="ul">{`Funder: for processing health fund or government claims and more complex patient funded invoices`}</li>
    </ul>
    <h1 {...{
      "id": "virtual-terminal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#virtual-terminal",
        "aria-label": "virtual terminal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual Terminal`}</h1>
    <p>{`The Medipass Virtual Terminal product mimics a physical EFTPOS terminal and allows a provider to enter cardholder information manually or request payment via SMS. Virtual Terminal can be run as a standalone experience separate from any health fund claiming experience. This may be used to process in-person payments or remote / telehealth payments.
Virtual Terminal currently supports:`}</p>
    <ul>
      <li parentName="ul">{`Credit cards: Visa or MasterCard`}</li>
      <li parentName="ul">{`Debit cards: Visa or MasterCard`}</li>
      <li parentName="ul">{`Apple Pay`}</li>
      <li parentName="ul">{`Google Pay`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/virtual-terminal.png",
        "alt": null
      }}></img></p>
    <h1 {...{
      "id": "funder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#funder",
        "aria-label": "funder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Funder`}</h1>
    <p>{`Medipass supports 61 unique health fund / scheme claiming and payment options. Note that providers need to be activated for each health fund.  Medipass (with HICAPS) can register provider details on behalf of each health fund with the exception of Medicare & DVA which requires a direct registration by the provider. Funder applications and activation status can be accessed in our provider portal: `}<a parentName="p" {...{
        "href": "https://connect.medipass.io"
      }}>{`connect.medipass.io`}</a></p>
    <p>{`Unique funding types include:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#comcare"
        }}><strong parentName="a">{`Comcare`}</strong></a>{`: Comcare workers compensation `}</li>
      <li parentName="ul"><strong parentName="li">{`DVA`}</strong>{`: Department of Veterans' Affairs`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hicaps"
        }}><strong parentName="a">{`HICAPS`}</strong></a>{`: including private health funds via HICAPS Go (no terminal required)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#icare"
        }}><strong parentName="a">{`icare`}</strong></a>{`: icare NSW`}</li>
      <li parentName="ul"><strong parentName="li">{`Medicare`}</strong>{`: Bulk Bill or Patient Claims via Medicare Online`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#patient-funded"
        }}><strong parentName="a">{`Patient funded`}</strong></a>{`: patient or claimant payments via credit/debit cards and Apple Pay/Google Pay`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#workcover-queensland"
        }}><strong parentName="a">{`Workcover Queensland`}</strong></a>{`: Workcover Queensland workers insurance`}</li>
      <li parentName="ul"><strong parentName="li">{`WSV`}</strong>{`: Workcover Victoria workers insurance`}</li>
      <li parentName="ul"><strong parentName="li">{`NDIS`}</strong>{`: NDIS plan managed and agency claims - coming soon`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/funders.png",
        "alt": null
      }}></img></p>
    <h3 {...{
      "id": "comcare",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#comcare",
        "aria-label": "comcare permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comcare`}</h3>
    <p>{`Comcare Australian government workers compensation claims.`}</p>
    <p>{`Funder code: `}<inlineCode parentName="p">{`comcare`}</inlineCode></p>
    <h3 {...{
      "id": "hicaps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hicaps",
        "aria-label": "hicaps permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HICAPS`}</h3>
    <p>{`Private health and overseas health insurers processed via HICAPS. No terminal is required for these health funds. Claims and gap payments can be processed and approved entirely online.`}</p>
    <p>{`Funder code: `}<inlineCode parentName="p">{`hicaps`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Health fund / scheme code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AAMI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AAMI Health Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ACA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ACA Health Benefits Fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AHB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defence Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AHM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AHM `}<br />{` `}<small><em parentName="td">{`This includes both private health insurance and overseas insurance`}</em></small></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`APIA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`APIA Health Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BUP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bupa`}<br />{` `}<small><em parentName="td">{`This includes both private health insurance and overseas insurance`}</em></small></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FHI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frank Health Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GMH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GMHBA Limited`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HBF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HBF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HCI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Health Care Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HEA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`health.com.au`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HIF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HIF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LHM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Peoplecare`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MBP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medibank Private Limited`}<br />{` `}<small><em parentName="td">{`This includes both private health insurance and overseas insurance`}</em></small></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NIB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NIB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NMW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nurses & Midwives Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PWA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phoenix Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QTA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Qantas Assure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`STL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`St. Lukes Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SUN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suncorp Health Insurance`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "icare",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#icare",
        "aria-label": "icare permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`icare`}</h3>
    <p>{`icare NSW claims. Currently, only workers insurance claims are enabled in production.`}</p>
    <p>{`Funder code: `}<inlineCode parentName="p">{`icare`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Health fund code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icare Dust Diseases fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HBCF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icare Home Building Compensation Fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IFNSW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icare Insurance for NSW government`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icare NSW motor accident claims through the Lifetime Care and Support Scheme`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icare Sports Injury fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IWC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icare Workers Insurance`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "patient-funded",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patient-funded",
        "aria-label": "patient funded permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patient Funded`}</h3>
    <p>{`Funder code: `}<inlineCode parentName="p">{`patient-funded`}</inlineCode></p>
    <p>{`Paid for by the Patient (Out of pocket). HICAPS Go 'gap' payments by default use this patient-funded option. This includes support for:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Scheme code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MDC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MasterCard Debit Cards`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MCC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MasterCard Credit Cards`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VDC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visa Debit Cards`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VCC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visa Credit Cards`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "workcover-queensland",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#workcover-queensland",
        "aria-label": "workcover queensland permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Workcover Queensland`}</h3>
    <p>{`Workcover Queensland workers insurance claims.`}</p>
    <p>{`Funder code: `}<inlineCode parentName="p">{`wcq`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      